/* ionic cards global styles */
ion-card-title {
    font-size: 13px;
    line-height: 22px;
}

ion-card {
    height: 100%;
    margin: 0 auto;
    box-shadow: none;
    --backgroud: var(--ion-item-background);
}

/* ionic list global styles */

ion-list, ion-list ion-item {
    --background: transparent;
}

ion-button:is(.btn) {
    --background: var(--ion-color-primary);
    --color: var(--ion-color-primary-contrast);
    --color-hover: var(--ion-color-dark-contrast);
    --box-shadow: none;
    --padding-start: 22px;
    --padding-end: 22px;
    --padding-top: 15px;
    --padding-bottom: 15px;
    --background-hover: var(--ion-color-dark);
    --background-hover-opacity: 1;
    --border-radius: 6px;
    font-size: var(--ion-font-size);
    height: auto;
    margin: 40px 0 20px;
}


ion-icon:is(.icon-small) {
    width: 25px;
    height: 25px;
}

ion-icon:is(.icon-meidum) {
    width: 40px;
    height: 40px;
}