/* Global font styles */

html.md,
body.md,
html,
body {
  --ion-default-font: "Poppins", sans-serif;
  font-size: var(--ion-font-size);
  font-weight: 500;
}

/* a {
  text-decoration: none;
  color: var(--ion-color-primary);
}

a:hover,
a:focus {
  color: var(--ion-link-color);
} */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

.flex-wrap {
  flex-wrap: wrap;
}

.container {
  max-width: 920px;
  margin: 0 auto;
  padding: 0 15px;
}

.container-s-sm {
  max-width: 576px;
  margin: 0 auto;
  padding: 0 15px;
}

.container-s-md {
  max-width: 768px;
  margin: 0 auto;
  padding: 0 15px;
}

.container-s-lg {
  max-width: 920px;
  margin: 0 auto;
  padding: 0 15px;
}

.container-s-xl {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.page_title {
  font-size: 42px;
  line-height: 56px;
}

.section_title {
  font-size: 20px;
  line-height: 32px;
}

.bg-transparent {
  background: transparent;
}

/* font-size styles */
:root .font-base {
  font-size: 13px;
  line-height: 22px;
}

.font-sm {
  font-size: 16px;
  line-height: 26px;
}

.font-md {
  font-size: 20px;
  line-height: 32px;
}

.font-lg {
  font-size: 42px;
  line-height: 56px;
}

.font-light {
  font-weight: 300;
}

.font-italic {
  font-style: italic;
}

/* searchbar styles starts */
.searchbar-input.sc-ion-searchbar-md {
  border-radius: 30px;
  min-height: 58px;
  padding: 15px 60px 15px 20px;
  border: 1px solid var(--ion-border-color);
  box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.061115);
}

.custom-searchbar-search-icon {
  right: 25px;
  left: auto;
  width: 40px;
  height: 40px;
  position: absolute;
  z-index: 1;
}

.searchbar-search-icon {
  display: none !important;
}

/* landing page styles starts */
.section_popular_questions .section_title {
  margin: 20px 10px;
  border-bottom: 1px solid #dfdfe1;
  padding-bottom: 10px;
}

.popular-questions-wrapper ion-grid {
  padding: 0;
}
.your-threads-page-wrapper ion-card:hover,
.popular-questions-wrapper ion-card:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  transform: scale(1.02);
}

.section_landing_content ion-icon {
  margin: 0 12px 0 0;
}

.section_landing_content ion-item {
  --padding-start: 0;
}

.section_landing_content .content_inner .section_img {
  margin: 40px auto 0;
  max-width: 280px;
}

.section_landing_content .section_wrapper {
  padding: 30px 0;
}

.section_landing_content .content_inner-wrap .page_title {
  max-width: 380px;
  margin-bottom: 30px;
}

.main_menu {
  border: none;
}

.sadhguru-gpt-logo-wrapper {
  width: 200px;
  margin: 0 auto;
}

.account-page-wrapper {
  padding: 20px 0;
}

.message-input-send-icon {
  right: 15px;
  left: auto;
  position: absolute;
  z-index: 9;
  top: calc(50% - 20px);
}

#chat-list-scroll-wrap ion-button {
  white-space: pre-wrap;
}

.my-thread-header-icon {
  width: 28px;
  height: 28px;
}

.new-chat {
  text-transform: capitalize;
  color: #c31c1c;
  border: 1px solid #aeb9ca;
  --box-shadow: none;
  --background: #ffffff;
  border-radius: 5px;
}

.sign-okta {
  text-transform: capitalize;
  --background: #c31c1c;
  max-width: 192px;
  margin: 0 auto;
  --padding-top: 20px;
  --padding-bottom: 20px;
  --box-shadow: none;
}

.new-chat-okta {
  text-transform: capitalize;
  color: #c31c1c;
  --border-color: #c31c1c;
  --border-style: solid;
  --border-width: 1px;
  --background: #ffffff;
  --box-shadow: none;
  max-width: 192px;
  margin: 0 auto;
}

.custom-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d8dfe9;
  border-radius: 100%;
  color: #576682;
  height: 100%;
}

.banner-landing {
  /* background-image: url(../../images/landing/banner-image.png); */
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 100%;
  max-height: 330px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid #dfdfe1;
}

.common-question-card {
  border-radius: 5px;
  border: 1px solid rgba(227, 227, 227, 1);
  box-shadow: 3px 3px 1px rgba(181, 206, 224, 1);
  opacity: 1;
  background-color: rgba(255, 255, 255, 1);
}

.news-card {
  border-radius: 5px;
  border: 1px solid rgba(227, 227, 227, 1);
  box-shadow: 6px 6px 1px rgba(181, 206, 224, 1);
  opacity: 1;
  background-color: rgba(255, 255, 255, 1);
}

.news-date {
  color: rgba(102, 102, 102, 1);
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 7px;
}

.news-title {
  color: rgba(68, 68, 68, 1);
  font-size: 16px;
  font-weight: 900;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 10px;
  margin-top: 0;
}

.news-details {
  color: rgba(68, 68, 68, 1);
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  line-height: 20px;
  margin-top: 0;
}

.profile-menu::part(icon) {
  content: url(../../images/landing/menu-icon.svg);
  width: 15px;
}

.profile-menu::part(text) {
  display: none;
}

.side-chat-list-item{
  --inner-padding-end:"0px";
}

/* custom dropdown styles */
.custom-dropdown {
  position: relative;
  width: 200px;
  border: 1px solid #ccc;
  cursor: pointer;
}

.dropdown-header {
  padding: 10px;
}

.dropdown-options {
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  position: absolute;
  top: -180px;
  left: 60px;
  width: 100%;
  background-color: white;
}

.dropdown-options li {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}

.dropdown-options li:last-child {
  border-bottom: none;
}

.dropdown-options li:hover {
  background-color: #f0f0f0;
}

/* custom dropdown styles */

/* this media query is for the desktop view in sync with the ionic props */
@media only screen and (min-width: 768px) {
  .delete-account-modal {
    --height: 230px;
  }
  .forgot-password-modal {
    --height: 360px;
  }
  .section_searchbar .searchbar-input {
    padding: 15px 60px 15px 30px;
  }

  .sadhguru-gpt-logo-wrapper {
    width: 100%;
  }

  .account-page-wrapper {
    padding: 90px 0;
  }
}
