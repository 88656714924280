/* Login Page's css */

.login-page-wrap {
  flex-direction: column;
  min-height: 100vh;
}

.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.login-form-wrap {
  max-width: 400px;
  margin: 0 auto;
  width: 100%;
}

.border-none {
  border: none;
}

.border-radius-25 {
  border-radius: 25px;
}

.login-form-outer-wrap {
  max-width: 750px;
  border: 1px solid #ccc;
  width: 100%;
  border-radius: 8px;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.pb-2 {
  padding-bottom: 0.5rem;
}

.pb-3 {
  padding-bottom: 1rem;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.py-3 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.px-3 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-4 {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.py-4 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-5 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.px-5 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.m-0 {
  margin: 0;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.my-3 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mt-0 {
  margin-top: 0;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-3 {
  margin-top: 1rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-3 {
  margin-bottom: 1rem;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.mb-5 {
  margin-bottom: 3rem;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mr-3 {
  margin-right: 1rem;
}

.mr-4 {
  margin-right: 1.5rem;
}

.mr-5 {
  margin-right: 3rem;
}

.ml-1 {
  margin-left: 0.25rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-3 {
  margin-left: 1rem;
}

.ml-4 {
  margin-left: 1.5rem;
}

.ml-5 {
  margin-left: 3rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.gap-1 {
  gap: 0.25rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-3 {
  gap: 1rem;
}

.gap-4 {
  gap: 1.5rem;
}

.gap-5 {
  gap: 3rem;
}

.gap-6 {
  gap: 4rem;
}

.fs-1 {
  font-size: 2.5rem;
}

.text-start {
  text-align: left;
}

.text-center {
  text-align: center;
}

.about-title {
  text-align: center;
  font-weight: 600;
  font-size: 36px;
}

.profile-container {
  display: flex;
}

.text-end {
  text-align: right;
}

.text-danger {
  color: #dc3545;
}

.login-title {
  margin-bottom: 1.5rem;
  margin-top: 0;
}

.google-signin-btn {
  padding: 15px 20px;
  background-color: rgb(0, 26, 70);
  border-radius: 28px;
  color: #fff;
}

button {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.button-native {
  border-radius: 25px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.text-primary {
  color: #007bff;
}

.sign-up-terms-and-privacy,
.sign-up-terms-and-privacy a {
  font-size: 12px;
  color: #343a40;
}

.forgot-pwd-header {
  border-bottom: 1px solid #ccc;
}

.footer-wrap {
  padding: 20px 0;
  border-top: 1px solid #dfdfe1;
  margin-top: 20px;
}

.footer_container {
  flex-direction: row-reverse;
}

.footer-social-links a {
  color: var(--ion-color-primary-tint);
  text-decoration: none;
}

.footer-quicklinks {
  gap: 30px;
}

.border-bottom {
  border-bottom: 1px solid #dfdfe1;
}

.btn-danger {
  background-color: #ff0000;
  border-color: #ff0000;
  color: #fff;
  padding: 0.8rem;
  border-radius: 5px;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
  color: #fff;
  padding: 0.8rem;
  border-radius: 5px;
}

.btn-dark {
  background-color: #343a40;
  border-color: #343a40;
  color: #fff;
  padding: 0.8rem;
  border-radius: 5px;
}

.bg-none {
  background-color: transparent;
}

.slim-scroll::-webkit-scrollbar {
  width: 10px;
}
.slim-scroll::-webkit-scrollbar-thumb {
  background-color: #d8d8d8;
  border-radius: 20px;
  border: 2px solid white;
}

.hover-visible {
  visibility: hidden;
}

.p-hover:hover .hover-visible {
  visibility: visible;
}

.react-markdown-message a {
  color: var(--ion-color-primary);
}

.profile-img {
  max-width: 381.22px;
  width: 100%;
}
.about-page-container {
  padding: 0 50px;
}

@media screen and (max-width: 820px) {
  .about-page-container {
    padding: 0 20px;
  }
  .profile-container {
    display: block;
  }
}

@media screen and (max-width: 1023px) {
  .footer_container {
    display: block;
    text-align: center;
  }
  .footer-social-links {
    margin: 30px 0;
  }
  .profile-img {
    max-width: 480px;
    margin: 0 auto;
    margin-bottom: 25px;
  }
}

@media screen and (min-width: 821px) and (max-width: 1599px) {
  .profile-container {
    justify-content: center;
  }
  .profile-img {
    width: 80%;
  }
  .profile-img ion-img {
    object-fit: cover;
    height: 100%;
  }
  .profile-content {
    width: 100%;
  }
}

.chat-container .footer-md::before {
  display: none;
}
